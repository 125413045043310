import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterSection,FooterCol,FooterTitle,FbIcon,InstaIcon,FooterInner,
    FooterUl,FooterLi,FooterMenu,FooterSocial, FooterPara} from './footer.style';
import Contact from "../Contact"
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query{
        instituteJson {
            Footer {
                DelayConstant
                FooterCol1{
                    FooterLogo{
                        childImageSharp{
                            fixed(width: 275){
                            ...GatsbyImageSharpFixed
                            }
                        }
                    }
                }
                FooterCol3{
                    FooterUl{
                        Text
                        Link
                    }
                }
                ContentHeading
                ContactFormHeading
                BottomFooterPara
                BottomFooterCopyright
            }
        }
    }
    `);
    return (
        <div>
        <FooterSection>
            <FooterInner>
                <Container>
                    <Row>
                        <Col md="4" sm="6">
                            <FooterCol>
                                <a href='/'>
                                    <GatsImg 
                                        fixed={JSONData.instituteJson.Footer.FooterCol1.FooterLogo.childImageSharp.fixed} 
                                        alt=""
                                    />

                                </a>

                            </FooterCol>

                            <FooterCol>
                                {/* <FooterPara>
                                    {JSONData.instituteJson.Footer.BottomFooterPara}
                                    {JSONData.instituteJson.Footer.BottomFooterCopyright}
                                </FooterPara>  */}
                            </FooterCol>
                            
                            <FooterCol>
                                <FooterSocial href="https://www.facebook.com/easyworkbr" aria-label={"Facebook Link"} target='_blank'>
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="https://www.instagram.com/easyworkbr_oficial/" aria-label={"Instagram Link"} target='_blank'>
                                    <InstaIcon/>
                                </FooterSocial>
                            </FooterCol>

                        </Col>
                        <Col md="4" sm="6">
                            <FooterCol>
                                <FooterTitle className='ContentHeading'> {JSONData.instituteJson.Footer.ContentHeading}</FooterTitle>
                                <FooterUl>
                                {
                                    JSONData.instituteJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <FooterLi key={idx}>
                                        <FooterMenu href={item.Link} target='_self'>
                                            {item.Text}
                                        </FooterMenu>
                                    </FooterLi>
                                    })
                                }
                                </FooterUl>
                            </FooterCol>
                        </Col>
                        <Col md="4" sm="6">
                            <FooterCol>
                            <FooterTitle>{JSONData.instituteJson.Footer.ContactFormHeading}</FooterTitle>
                                <Contact/>
                            </FooterCol>
                        </Col>
                    </Row>
            </Container>
            </FooterInner>
        </FooterSection>
        
        </div>
    );
}

export default Footer;
import React,{Component}from 'react';
import {ContactWrapper,Form,FormGroup,FormGroupSubmit,NameInput,ContactFormSpanErr,TextCenter,SubmitBtn,
    ContactFormSpanSuccess
} from './contact.style';
import {Row,Col} from '@bootstrap-styled/v4';
import { StaticQuery, graphql } from 'gatsby';
import { mailTo }  from '../../../services/backend'

const WRNG_MSG_TIMEOUT = 3000;

class Contact extends Component{

    constructor(props) {
        super(props);
        this.state = {
            errors:[],
            contactForm_name:"",
            contactForm_email:"",
            contactForm_phone:"",
            contactForm_msg:""
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.contactForm_name === "")
        {
            error_flag = true;
            errors['contactForm_name'] = "Por favor insira um nome.";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_email === "")
        {
            error_flag = true;
            errors['contactForm_email'] = "Por favor insira um email.";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_email !== "")
        {
            var patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!patternEmail.test(this.state.contactForm_email)) {
                error_flag = true;
                errors["contactForm_email"] = "Por favor insira um e-mail válido";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.contactForm_course === "")
        {
            error_flag = true;
            errors["contactForm_course"] = "Por favor insira uma mensagem";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.contactForm_phone === "")
        {
            error_flag = true;
            errors["contactForm_phone"] = "Por favor insira seu telefone";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    contactFormSubmit = (e)=>{
        e.preventDefault();
        if(!this.validateForm())
        {

            const data = { 
                name: this.state.contactForm_name,
                to: this.state.contactForm_email,
                phone: this.state.contactForm_phone,
                message: this.state.contactForm_msg,
            }
            
            try {
                
            mailTo(data);

            console.log("Nome: "+this.state.contactForm_name);
            console.log("Email: "+this.state.contactForm_email);
            console.log("Fone: "+this.state.contactForm_phone);
            console.log("Mensagem: "+this.state.contactForm_msg);

            document.getElementById("success_contactForm_message").innerHTML = "Obrigado por enviar sua mensagem.";
            this.setState({
                contactForm_name:"",
                contactForm_email:"",
                contactForm_phone:"",
                contactForm_msg:"",
                errors:[]
            },()=>{
                setTimeout(function(){
                    document.getElementById("success_contactForm_message").innerHTML = "";
               },WRNG_MSG_TIMEOUT);
            });
                
            } catch (error) {
                console.log(error)
            }

            
        }
    }

    render() {
        return (
            <ContactWrapper id="contact">

                <Form id="contactForm">
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <NameInput aria-label="Nome" type="text" placeholder="Nome *" name="contactForm_name" onChange={this.handleChange} value={this.state.contactForm_name}/>
                                <ContactFormSpanErr id="err_contactForm_name">{this.state.errors.contactForm_name}</ContactFormSpanErr>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <NameInput aria-label="Email" type="text" placeholder="E-mail *" name="contactForm_email" onChange={this.handleChange} value={this.state.contactForm_email}/>
                                <ContactFormSpanErr id="err_contactForm_email">{this.state.errors.contactForm_email}</ContactFormSpanErr>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <NameInput aria-label="Telefone" type="text" placeholder="Telefone *" name="contactForm_phone" onChange={this.handleChange} value={this.state.contactForm_phone}/>
                                <ContactFormSpanErr id="err_contactForm_phone">{this.state.errors.contactForm_phone}</ContactFormSpanErr>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroup>
                                <NameInput aria-label="Mensagem" type="text" placeholder="Mensagem" name="contactForm_msg" onChange={this.handleChange} value={this.state.contactForm_msg}/>
                                <ContactFormSpanErr id="err_contactForm_msg">{this.state.errors.contactForm_msg}</ContactFormSpanErr>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <FormGroupSubmit>
                                <TextCenter>
                                    <SubmitBtn href="#" onClick={this.contactFormSubmit}>Enviar</SubmitBtn>
                                </TextCenter>
                            </FormGroupSubmit>
                        </Col>
                    </Row>
                    <ContactFormSpanSuccess id="success_contactForm_message"></ContactFormSpanSuccess>
                </Form>

            </ContactWrapper>
        );
    }
}
export default () => (
    <StaticQuery
        query={graphql`
            query {
                    instituteJson{
                        Contact{
                            ContactFormHeading
                    }
                }
            }
            
        `}
        render={(data) => (
            <Contact instituteJson={data.instituteJson}/>
        )}
    />
  )
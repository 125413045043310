import axios from 'axios'

const config = {
    // host: 'http://ec2-18-231-52-240.sa-east-1.compute.amazonaws.com:8001/api',
    // host: 'http://localhost:6001',
    host: 'https://easywork.vps-kinghost.net:8001',
    timeout: 10000,
};

//-----------------------------
//Configuração API
export const Api = axios.create({
    baseURL: config.host,
    timeout: config.timeout,
});